import { createTheme } from '@mui/material';
import { fiFI } from '@mui/x-date-pickers/locales';

// If you change these. Remember to check if design document needs to be updated.

const theme = createTheme({
  palette: {
    primary: {
      main: '#0072C6',
    },
    secondary: {
      main: '#F5A3C7',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: '300',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: '300',
        },
      },
    },
  },
},
fiFI,
);

export default theme;
