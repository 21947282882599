import { useCallback, useEffect, useState } from 'react';
import useFetch from './useFetch';
import api from '../api/Api';
import { Room } from '../models/Room';
import { RoomOverviewResponse } from '../models/RoomOverviewResponse';

export default function useRooms() {

  const getRoomsData = useCallback( async () => {
    return api.getRooms();
  },
  [],
  );

  const { fetchData, data, fetching, fetchFailed } = useFetch<RoomOverviewResponse[]>(getRoomsData);
  const [sortedRooms, setSortedRooms] = useState<Room[] | undefined>(undefined);

  useEffect(() => {
    try {
      void fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [fetchData]);

  useEffect(() => {
    if (!data) return;

    let newRoomList: Room[] = [];
    for (let i = 0; data.length > i; i++) {
      newRoomList.push(data[i].room);
    }
    setSortedRooms(newRoomList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
  }, [data]);


  return {
    rooms: sortedRooms, fetching, fetchFailed,
  };
}
