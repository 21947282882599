import { VirtausEvent } from '../models/VirtausEvent';

const msInMinute = 60 * 1000;

class DateTimeService {
  getDateTimeHumanReadable(): string {
    const now = new Date();
    return now.getDay() + '.' + now.getMonth() + '.' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes();
  }
  getYesterdayCalendarString(): string {
    const today = new Date();
    const yesterday = new Date(today.getDate()-1);
    const year = yesterday.getFullYear();
    const month = yesterday.getMonth();
    const day = yesterday.getDay();
    const yesterdayCalendarString = year + '-' + month + '-' + day;
    return yesterdayCalendarString;
  }

  formatToTime(date?: string) {
    if (!date) return '';
    return Intl.DateTimeFormat('FI-fi', { timeStyle: 'short' }).format(Date.parse(date));
  }

  minutesToEventEnd(event: VirtausEvent): number {
    if (!event.endTime) {
      return 0;
    }

    return Math.ceil((new Date(event.endTime).getTime() - new Date().getTime()) / msInMinute);
  }

  minutesSince(startTime: string): number {
    return Math.round(((new Date().getTime() - new Date(startTime).getTime()) / msInMinute));
  }

  minutesFromNow(toDate: Date | null): number | null {
    if (!toDate) {
      return null;
    }
    return Math.round((new Date(toDate).getTime() - new Date().getTime()) / msInMinute);
  }

  minutesFromEventStartToEnd(event: VirtausEvent): number | null {
    if (!event.startTime || !event.endTime) return null;

    return (new Date(event.endTime).getTime() - new Date(event.startTime).getTime()) / msInMinute;
  }

  toLocalISOString(date: Date): string {
    const localDateString = new Date(date.getTime() - (date.getTimezoneOffset() * msInMinute)).toISOString();
    return localDateString.slice(0, -1);
  }

  subtractMinutesFromDate(date: string, minutesToSubtract: number): string {
    const subtractedDate = new Date(new Date(date).getTime() - (minutesToSubtract * msInMinute)).toISOString();
    return subtractedDate;
  }

  addMinutesToDate(date: string, minutesToAdd: number): string {
    const sumDate = new Date(new Date(date).getTime() + (minutesToAdd * msInMinute)).toISOString();
    return sumDate;
  }

  getTimestamp(): string {
    return new Date().toISOString();
  }

}

const dateTimeService = new DateTimeService();
export default dateTimeService;