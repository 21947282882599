import { createContext, FC, ReactNode, useContext, useState } from 'react';
import AuthContext from './AuthProvider';
import api from '../api/Api';
import { LogEventType } from '../models/LogEventType';
import eventLogService from '../services/EventLogService';

interface HomeRoomContextType {
  homeRoomId: number | undefined;
  noHomeRoom: boolean;
  updateHomeRoomId: (roomId: number | undefined) => void;
  updateNoHomeRoom: (newNoHomeRoomValue: boolean) => void;
  removeHomeRoomId: () => void;
}

const HomeRoomContext = createContext<HomeRoomContextType>({
  homeRoomId: undefined,
  noHomeRoom: false,
  updateHomeRoomId: () => {},
  updateNoHomeRoom: () => {},
  removeHomeRoomId: () => {},
});

interface HomeRoomProviderProps {
  children: ReactNode;
}

export const HomeRoomProvider:FC<HomeRoomProviderProps> = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const [noHomeRoom, setNoHomeRoom] = useState(sessionStorage.getItem('noHomeRoom') ? true : false);
  const [homeRoomId, setHomeRoomId] = useState<number | undefined>((!noHomeRoom && sessionStorage.getItem('homeRoomId'))
    ? parseInt(sessionStorage.getItem('homeRoomId') as string)
    : undefined);

  function updateHomeRoomId(roomId: number | undefined) {
    if (!auth.staffMemberId) return;

    if (roomId) {
      api.setHomeRoom(roomId, auth.staffMemberId).then()
        .catch((error) => {console.log(`Virhe ${error.response.status}: ${error.response.data}`);})
        .finally(() => {
          sessionStorage.setItem('homeRoomId', JSON.stringify(roomId));
          sessionStorage.removeItem('noHomeRoom');
          eventLogService.logEvent({ eventType: LogEventType.NURSE_LOGGED_IN_ROOM, roomId: roomId, staffMemberId: auth.staffMemberId });
        });
    }
    setHomeRoomId(roomId);
  }

  function updateNoHomeRoom(newNoHomeRoomValue: boolean) {
    if (newNoHomeRoomValue) {
      sessionStorage.setItem('noHomeRoom', 'true');
      setNoHomeRoom(true);
    } else {
      sessionStorage.removeItem('noHomeRoom');
      setNoHomeRoom(false);
    }
  }

  function removeHomeRoomId() {
    sessionStorage.removeItem('homeRoomId');
  }

  return (
    <HomeRoomContext.Provider value={{ homeRoomId, noHomeRoom, updateHomeRoomId, updateNoHomeRoom, removeHomeRoomId }}>
      {children}
    </HomeRoomContext.Provider>
  );
};

export default HomeRoomContext;