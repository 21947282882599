import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import HomeRoomContext from '../../context/HomeRoomProvider';
import useRooms from '../../hooks/useRooms';


interface ChangeRoomDialogProps {
  open: boolean;
  closeDialog: () => void;
  currentRoom?: number | null;
  label?: string;
  showFlightControlSelection?: boolean;
}

const ChangeRoomDialog: FC<ChangeRoomDialogProps> = ({
  open, currentRoom, closeDialog,
  label = 'Vaihda huone', showFlightControlSelection = false,
}) => {
  const { rooms, fetching, fetchFailed } = useRooms();
  const [selectedRoom, setSelectedRoom] = useState(currentRoom);
  const [noHomeRoom, setNoHomeRoom] = useState(false);
  const { updateHomeRoomId, updateNoHomeRoom } = useContext(HomeRoomContext);

  function handleConfirm() {
    if (noHomeRoom) {
      updateNoHomeRoom(true);
    } else if (selectedRoom) {
      updateHomeRoomId(selectedRoom);
    }
    closeDialog();
  }

  return <Dialog open={open}>
    <DialogTitle >{label}</DialogTitle>
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4, paddingX: '24px', paddingY: '8px' }}>
      <Typography>Hoitohuone asetetaan tälle laitteelle oletushuoneeksi. Voit muuttaa huonetta huonenäkymässä.
         Jos toimit lennonjohtajaroolissa, valitse 'Olen lennonjohtaja'</Typography>
      {rooms && !fetching &&
            <>
              {showFlightControlSelection &&
              <FormControl fullWidth>
                <FormControlLabel label="Olen lennonjohtaja" control={<Checkbox value={noHomeRoom} onChange={(event) => setNoHomeRoom(event.target.checked)} />} />
              </FormControl>
              }
              {!noHomeRoom &&
                  <FormControl fullWidth>
                    <InputLabel id="room-label">Hoitohuone</InputLabel>
                    <Select
                      value={selectedRoom && selectedRoom !== -1 ? selectedRoom : ''}
                      id="room"
                      label="Huone"
                      labelId='room-label'
                      disabled={rooms.length < 1}
                      placeholder={rooms.length < 1 ? 'Ei huoneita' : 'Valitse huone'}
                      onChange={e => setSelectedRoom(e.target.value ? +e.target.value : undefined)}>
                      {rooms.map((room) => <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>)}
                    </Select>
                  </FormControl>
              }
              {noHomeRoom && <Alert severity='info'>Lennonjohtajan ei tarvitse valita hoitohuonetta</Alert>}
            </>
      }
      {fetching && <CircularProgress />}
      {fetchFailed && <Alert severity='error'>Huonelistan haku epäonnistui</Alert>}
      <Box sx={{ display: 'flex', gap: 4, justifyContent: 'center', marginTop: 4 }}>
        <Button onClick={closeDialog} size="large">Peruuta</Button>
        <Button disabled={!selectedRoom && !noHomeRoom} onClick={() => handleConfirm()} variant="contained" size="large">Ok</Button>
      </Box>
    </Box>
  </Dialog>;
};

export default ChangeRoomDialog;