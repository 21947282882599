import axios from 'axios';
import { AvailableRooms } from '../models/AvailableRooms';
import { ChangeEventValues } from '../models/ChangeEventValues';
import { EventPatientResponse } from '../models/EventPatientResponse';
import { EventStaffMemberRequest } from '../models/EventStaffMemberRequest';
import { EventType } from '../models/EventType';
import { LogEvent } from '../models/LogEvent';
import { NextHappening } from '../models/NextHappening';
import { InvitableRoles } from '../models/Role';
import { RoomOverviewResponse } from '../models/RoomOverviewResponse';
import { StaffMember } from '../models/StaffMember';
import { StaffOverviewResponse } from '../models/StaffOverviewResponse';
import { VirtausEvent } from '../models/VirtausEvent';
import dateTimeService from '../services/DateTimeService';


class Api {
  async getRoomEvents(roomId: number): Promise<VirtausEvent[]> {
    const response = await axios.get('/api/events/room/' + roomId + '/undone');
    return response.data;
  }

  async getStaffMemberDetails(staffMemberId: number): Promise<StaffMember> {
    const response = await axios.get('/api/staff-members/' + staffMemberId);
    return response.data;
  }

  async editEvent(values: ChangeEventValues): Promise<any> {
    const response = axios.put('/api/events', JSON.stringify(values),
      { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
    return response;
  }

  async addStaffMemberToEvent(eventId: number, staffId?: number): Promise<any> {
    const now = dateTimeService.toLocalISOString(new Date());
    const response = axios.post('api/staff-members/events', JSON.stringify({ eventId, staffId, confirmed: now, arrived: now, declined: false }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
    return response;
  }

  async confirmPatientArrival(eventId: number, roomId: number): Promise<any> {
    const response = axios.put('/api/events/confirm-patient-arrival', { eventId, roomId }, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
    return response;
  }

  async getStaffOverview(): Promise<StaffOverviewResponse> {
    const response = await axios.get('/api/staff-members/overview');
    return response.data;
  }

  async getEvent(eventId: number): Promise<any> {
    const response = await axios.get('api/events/'+eventId);
    return response.data;
  }

  async getRooms(): Promise<RoomOverviewResponse[]> {
    const response = await axios.get('/api/rooms/all');
    return response.data;
  }

  async getUninvitedPatients(): Promise<EventPatientResponse[]> {
    const response = await axios.get('/api/events/uninvited-patients');
    return response.data;
  }

  async getInvitedPatients(): Promise<EventPatientResponse[]> {
    const response = await axios.get('/api/events/invited-patients');
    return response.data;
  }

  async getAvailableRooms(): Promise<AvailableRooms> {
    const response = await axios.get('/api/rooms/suggest');
    return response.data;
  }

  async logEvent(logEvent: LogEvent): Promise<any> {
    const response = await axios.post('/api/eventlog', logEvent);
    return response.data;
  }

  importCSVFile = async (file: FormData) => {
    return axios.post('/api/events/csv-import-apotti', file);
  };

  getUserAndEvents = (staffId: number) => {
    return axios.get(`/api/staff-members/${staffId}`);
  };

  getEventDetails = (eventId: number) => {
    return axios.get(`/api/events/${eventId}`);
  };

  answerAssignment = (eventId: number, staffId: number, willParticipate: boolean) => {
    const now = dateTimeService.toLocalISOString(new Date());
    return axios.put('/api/staff-members/events', JSON.stringify({
      eventId: eventId,
      staffId: staffId,
      declined: !willParticipate,
      confirmed: willParticipate ? now : null,
      arrived: willParticipate ? now : null,
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  changeEventEndTime = (eventId: number, minutes: number) => {
    return axios.put('/api/events', JSON.stringify({
      id: eventId,
      timeChange: {
        type: 'PLUS',
        minutes: minutes,
      },
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  createMyEvent = (eventType: EventType, minutes: number) => {
    return axios.post('/api/events', JSON.stringify({
      type: eventType,
      duration: minutes,
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  setMyEventDone = (eventId: number) => {
    return axios.put('/api/events', JSON.stringify({
      id: eventId,
      verifiedDoneTime: dateTimeService.toLocalISOString(new Date()),
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  leaveMyEvent = (eventId: number, staffId: number) => {
    return axios.put('/api/staff-members/events', JSON.stringify({
      eventId: eventId,
      staffId: staffId,
      left: dateTimeService.toLocalISOString(new Date()),
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  callStaffToEvent = (eventId: number, wantedStaffRole: InvitableRoles) => {
    return axios.post('/api/staff-members/call-staff', JSON.stringify({
      eventId: eventId,
      type: wantedStaffRole,
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  getStaffMember = (staffId: number) => {
    return axios.get(`/api/staff-members/${staffId}`);
  };

  updateEventMember = (values: EventStaffMemberRequest) => {
    return axios.put('/api/staff-members/events', values);
  };

  startDay = (staffMemberId: number) => {
    return axios.put('/api/staff-members/', { id: staffMemberId, dayStarted: dateTimeService.toLocalISOString(new Date()) });
  };

  endDay = (staffMemberId: number) => {
    return axios.put('/api/staff-members/', { id: staffMemberId, dayEnded: dateTimeService.toLocalISOString(new Date()) });
  };

  setHomeRoom = (roomId: number, ownerStaffMemberId: number) => {
    return axios.put('/api/rooms', { roomId, ownerStaffMemberId });
  };

  getNextAvailableStaffMember = (wantedStaffRole: InvitableRoles) => {
    return axios.get(`/api/staff-members/next-available/${wantedStaffRole}`);
  };

  setCleaningTime = (event: VirtausEvent, minutes: number) => {
    return axios.put('/api/events', JSON.stringify({
      id: event.id,
      cleaningTime: minutes,
      timeChange: {
        type: 'PLUS',
        minutes: minutes - event.cleaningTime,
      },
      nextHappening: NextHappening.ROOM_CLEANUP,
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  removePatientFromRoom = (eventId: number) => {
    return axios.put('/api/events/patient-and-room/', JSON.stringify({
      id: eventId,
      roomId: null,
      patientArrived: null,
    }), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    });
  };

  getPatientsSheet = (startTime: number, endTime: number) => {
    return axios.get('/api/data-export/patients/' + startTime + '/' + endTime, {
      headers: { 'Content-Type': 'text/csv;charset=UTF-8' },
    });
  };
  getRoomsSheet = (startTime: number, endTime: number) => {
    return axios.get('/api/data-export/rooms/' + startTime + '/' + endTime, {
      headers: { 'Content-Type': 'text/csv;charset=UTF-8' },
    });
  };
  getStaffMembersSheet = (startTime: number, endTime: number) => {
    return axios.get('/api/data-export/staff-members/' + startTime + '/' + endTime, {
      headers: { 'Content-Type': 'text/csv;charset=UTF-8' },
    });
  };
  getRawDataSheet = (startTime: number, endTime: number) => {
    return axios.get('/api/data-export/raw-data/' + startTime + '/' + endTime);
  };
}

const api = new Api;
export default api;