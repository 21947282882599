import styled from '@emotion/styled';
import {
  AppBar,
  Box,
  Button,
  Container,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';

const pages = [
  { name: 'Huone', url: '/room' },
  { name: 'Tilannekuva', url: '/operational' },
  { name: 'Oma tila', url: '/mystatus' },
];

interface NavigationProps {}

const Navigation: FC<NavigationProps> = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const goTo = (url: string) => {
    setAnchorElNav(null);
    navigate(url);
  };

  const Logo = styled('img')`
    height: 24px;
    margin-right: 8px;
  `;

  return (
    <AppBar component="nav" position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ minWidth: '64px', display: 'flex', alignContent: 'center' }}>
            <Logo src={logo} className="logo" alt="logo" />
          </Box>
          <Typography>VIRTAUS</Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <Icon>menu</Icon>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={goTo}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => goTo(page.url)}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
            justifyContent={'center'}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => goTo(page.url)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <IconButton sx={{ p: 0 }} color="inherit" onClick={() => goTo('/settings')}>
            <Icon>settings</Icon>
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
