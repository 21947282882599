import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { EventLockProvider } from './context/EventLockProvider';
import { HomeRoomProvider } from './context/HomeRoomProvider';
import { NextProfessionalProvider } from './context/NextProfessionalProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <HomeRoomProvider>
        <NextProfessionalProvider>
          <EventLockProvider>
            <Suspense fallback={<CircularProgress />}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Suspense>
          </EventLockProvider>
        </NextProfessionalProvider>
      </HomeRoomProvider>
    </AuthProvider>
  </React.StrictMode>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
