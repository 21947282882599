import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useContext } from 'react';
import { initializeInterceptors } from './api/interceptors';
import AuthContext from './context/AuthProvider';
import theme from './theme';
import ContainerView from './views/containerView/ContainerView';

function App() {
  const { auth, removeAuth } = useContext(AuthContext);
  initializeInterceptors(auth, removeAuth);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
      <ThemeProvider theme={theme}>
        <div className="App">
          <ContainerView />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
