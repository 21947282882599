import dateTimeService from './DateTimeService';
import defaultsService from './DefaultsService';
import { NextHappening } from '../models/NextHappening';
import { VirtausEvent, VirtausEventStaffMember } from '../models/VirtausEvent';

class EventService {

  // TODO: This doesn't seem to work in production and causes "ghost invitations in MyStatusView"
  isUsersCurrentEvent(staffId: number | undefined, event: VirtausEvent): boolean {
    if (!staffId || !event.startTime || !event.endTime || event.verifiedDoneTimestamp || event.staffMembers.length === 0) {
      return false;
    }
    const now = new Date();
    const startTime = new Date(Date.parse(event.startTime));
    const endTime = new Date(Date.parse(event.endTime));
    if (now < startTime || now > endTime) {
      return false;
    }
    const userCurrentlyInEvent = event.staffMembers.find((staffMember: VirtausEventStaffMember) => {
      return staffMember.staffId === staffId && staffMember.arrived && !staffMember.left;
    });
    if (userCurrentlyInEvent) {
      return true;
    }
    return false;
  }

  currentlyInvitedUser(event: VirtausEvent): VirtausEventStaffMember | null {
    if (event.staffMembers.length === 0) {
      return null;
    }
    const userCurrentlyBeingAsked = event.staffMembers.find((staffMember: VirtausEventStaffMember) => {
      return !staffMember.confirmed && !staffMember.arrived && !staffMember.left;
    });
    if (userCurrentlyBeingAsked) {
      return userCurrentlyBeingAsked;
    }
    return null;
  }

  isConfirmedNewUser(event: VirtausEvent, originalStaffMembers: VirtausEventStaffMember[]): VirtausEventStaffMember | null {
    if (event.staffMembers.length === 0) {
      return null;
    }

    const userRecentlyConfirmed = event.staffMembers.find((staffMember: VirtausEventStaffMember) => {
      if (!staffMember.confirmed) {
        return false;
      }
      if (originalStaffMembers.some(originalStaffMember => staffMember.staffId === originalStaffMember.staffId)) {
        return false;
      }
      return true;
    });
    return userRecentlyConfirmed ?? null;
  }

  usersParticipatedInRoomEvent(event: VirtausEvent): VirtausEventStaffMember[] {
    const usersPresentInEvent = event.staffMembers.filter((staffMember: VirtausEventStaffMember) => {
      return staffMember.confirmed && staffMember.arrived;
    });
    return usersPresentInEvent;
  }

  isCurrentEvent(event: VirtausEvent): boolean {
    if (!event.startTime || !event.endTime) return false;

    const now = new Date();
    const startTime = new Date(Date.parse(event.startTime));
    const endTime = new Date(Date.parse(event.endTime));
    if (now < startTime || now > endTime) {
      return false;
    } else {
      return true;
    }
  }

  isEventTimeLocked(event: VirtausEvent | undefined | null): boolean {
    if (!event) {
      return false;
    }
    return dateTimeService.minutesToEventEnd(event) - event.cleaningTime <= defaultsService.LOCK_EVENT_BEFORE_END;
  };

  hasEventTimeRanOut(event: VirtausEvent | undefined | null): boolean {
    if (!event || !event.endTime) {
      return false;
    }

    const now = new Date();
    const endTime = new Date(Date.parse(event.endTime));
    const ensureClientActsBeforeServerAutocloseSeconds = 10;
    return now.getTime() > endTime.getTime() - (ensureClientActsBeforeServerAutocloseSeconds * 1000);
  };

  nextEventToStart(events: VirtausEvent[]): VirtausEvent | undefined {
    const futureEvents = events.filter(event => {
      return !event.startTime && event.patient.arrived;
    }).sort((a, b) => {
      return new Date(a.patient.arrived as string).getTime() - new Date(b.patient.arrived as string).getTime();
    });
    if (futureEvents.length === 0) {
      return undefined;
    }
    return futureEvents[0];
  }

  hasEventChanged(originalEvent: VirtausEvent | undefined, newEvent: VirtausEvent | undefined): boolean {
    if ((!originalEvent && newEvent) || (originalEvent && !newEvent)) {
      return true;
    }
    if (!originalEvent || !newEvent) {
      return false;
    }

    const followedEventKeys: (keyof VirtausEvent)[] = ['id', 'startTime', 'endTime', 'cleaningTime', 'nextHappening'];
    for (const key of followedEventKeys) {
      const originalValue = originalEvent[key];
      const newValue = newEvent[key];
      if (newValue !== originalValue) {
        return true;
      }
    }

    if (newEvent.staffMembers.length !== originalEvent.staffMembers.length) {
      return true;
    }
    for (const newStaffMember of newEvent.staffMembers) {
      if (!originalEvent.staffMembers.some((originalStaffMember: VirtausEventStaffMember) => {
        return (
          newStaffMember.staffId === originalStaffMember.staffId &&
          newStaffMember.confirmed === originalStaffMember.confirmed &&
          newStaffMember.arrived === originalStaffMember.arrived &&
          newStaffMember.left === originalStaffMember.left
        );
      })) {
        return true;
      }
    }
    return false;
  }

  isCleaningActive(event: VirtausEvent): boolean {
    const minutesToEventEnd = dateTimeService.minutesToEventEnd(event);
    return event.cleaningTime >= minutesToEventEnd;
  }

  isProfessionalInvited(event?: VirtausEvent): boolean {
    if (!event) return false;
    return [NextHappening.HML, NextHappening.SHG, NextHappening.ANY].includes(event.nextHappening as NextHappening);
  };

  amountOfStaffMembersConfirmedAndNotLeft(event: VirtausEvent): number {
    const confirmedNotLeftStaffMembers = event.staffMembers.filter(staffMember => staffMember.confirmed != null && staffMember.left == null).length;
    return confirmedNotLeftStaffMembers;
  }

  getEventNurse(event: VirtausEvent | undefined): number | undefined {
    if (!event) return;
    const arrivedStaffMembers = event.staffMembers.filter(item => item.arrived);
    const nurse = arrivedStaffMembers.reduce((prev, curr) => {
      return new Date(prev.arrived as string) < new Date(curr.arrived as string) ? prev : curr;
    });
    return nurse.staffId;
  }
}

const eventService = new EventService();
export default eventService;