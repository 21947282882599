export enum LogEventType {
  PATIENT_INVITED = 'PATIENT_INVITED',
  PATIENT_ARRIVED = 'PATIENT_ARRIVED',
  PATIENT_CARE_STARTED = 'PATIENT_CARE_STARTED',
  PATIENT_CARE_ENDED = 'PATIENT_CARE_ENDED',
  NURSE_LOGGED_IN_ROOM = 'NURSE_LOGGED_IN_ROOM',
  ROOM_CLEAN_STARTED = 'ROOM_CLEAN_STARTED',
  ROOM_CLEAN_ENDED = 'ROOM_CLEAN_ENDED',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  INVITATION_REJECTED = 'INVITATION_REJECTED',
  PROFESSIONAL_LEAVES_ROOM = 'PROFESSIONAL_LEAVES_ROOM',
  DOCUMENTATION_STARTED = 'DOCUMENTATION_STARTED',
  DOCUMENTATION_ENDED = 'DOCUMENTATION_ENDED',
  BREAK_STARTED = 'BREAK_STARTED'
}
