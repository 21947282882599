
class DefaultsService {
  ROOM_CLEANING_TIME = 15;
  FIRST_SPECIALIST = 60;
  NOT_FIRST_SPECIALIST = 45;
  LOCK_EVENT_BEFORE_END = 15;
  EVENT_MAX_MINUTES = 120;
  POLL_DELAY_MS = 1000;
  POLL_SLOW_DELAY_MS = 5000;
  MAX_CLEANING_MINUTES = 30;
}
const defaultsService = new DefaultsService();
export default defaultsService;