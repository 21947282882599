import { useCallback, useState } from 'react';

export default function useFetch<T>(fetchMethod: () => Promise<T | undefined>) {
  const [data, setData] = useState<T | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const [fetchFailed, setFetchFailed] = useState(false);

  const fetchData = useCallback(async () => {
    setFetching(true);
    setFetchFailed(false);

    try {
      const response = await fetchMethod();
      if (response !== undefined) {
        setData(response);
      }
      setFetching(false);
    } catch (error) {
      setFetchFailed(true);
      setFetching(false);
    }
  },
  [fetchMethod],
  );

  return { fetchData, data, fetching, fetchFailed };

};