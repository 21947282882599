import { Alert, Button, CircularProgress, Container, Icon, Snackbar } from '@mui/material';
import { FC, Suspense, lazy, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import api from '../../api/Api';
import invitation_received from '../../assets/invitation_received_5.wav';
import Navigation from '../../components/navigation/Navigation';
import AuthContext from '../../context/AuthProvider';
import HomeRoomContext from '../../context/HomeRoomProvider';
import { Role } from '../../models/Role';
import defaultsService from '../../services/DefaultsService';
import ChangeRoomDialog from '../roomView/ChangeRoomDialog';


interface ContainerViewProps {}

const RoomView = lazy(() => import('../roomView/RoomView'));
const OperationalView = lazy(
  () => import('../operationalView/OperationalView'),
);
const MyStatusView = lazy(() => import('../myStatusView/MyStatusView'));
const SettingsView = lazy(() => import('../settingsView/SettingsView'));
const LoginView = lazy(() => import('../loginView/LoginView'));

const ContainerView: FC<ContainerViewProps> = () => {
  const { auth } = useContext(AuthContext);
  const { homeRoomId, noHomeRoom } = useContext(HomeRoomContext);
  const [changeRoomDialogOpen, setChangeRoomDialogOpen] = useState(false);
  let pollingActive = false;
  let pollingTimers: any[] = [];
  const [networkError, setNetworkError] = useState('');
  const [userEventsPollReply, setUserEventsPollReply] = useState<number[]>([]);
  const [userNotification, setUserNotification] = useState('');
  const [soundOnReminder, setSoundOnReminder] = useState('');
  let userNotificationActive = false;
  const invitationReceivedSound = new Audio(invitation_received);

  useEffect(() => {
    if (!auth || !auth.roles) return;
    if (auth.roles.some((role) => role === Role.HH) && !homeRoomId && !noHomeRoom) {
      setChangeRoomDialogOpen(true);
    }
  }, [auth]);

  useEffect(() => {
    if (!auth.accessToken) return;
    if (!pollingActive) {
      startNetworkPolling();
    }
    return (() => {
      stopNetworkPolling();
      setUserEventsPollReply([]);
      document.title = 'Virtaus';
    });
  }, [auth]);

  const refreshUserEvents = (showNotifications: boolean) => {
    if (!auth.staffMemberId) return;
    api.getUserAndEvents(auth.staffMemberId).then(
      response => {
        setUserEventsPollReply(existingEventIds => {
          const refreshedEventIds = response.data.events ?? [];
          for (const eventId of refreshedEventIds) {
            if (!existingEventIds.includes(eventId) && showNotifications) {
              notifyUserOfNewEvents();
              break;
            }
          }
          return refreshedEventIds;
        });
      },
      error => {
        setNetworkError(`Virhe: tapahtumien haku käyttäjälle ei onnistunut. Lisätiedot: ${error.message}`);
      },
    );
  };

  const startNetworkPolling = () => {
    pollingActive = true;

    refreshUserEvents(false);
    pollingTimers.push(setInterval(() => {
      refreshUserEvents(true);
    }, defaultsService.POLL_DELAY_MS));
  };

  const stopNetworkPolling = () => {
    pollingTimers.forEach(timer => {
      clearInterval(timer);
    });
    pollingTimers = [];
    pollingActive = false;
  };

  const notifyUserOfNewEvents = () => {
    if (auth.roles?.includes(Role.HH)) return;

    const browserTabIsNotActive = document.hidden;
    if (browserTabIsNotActive && !userNotificationActive) {
      userNotificationActive = true;
      let flashNotificationsToShow = 10 * 2;
      const flashingAnimationTimer = setInterval(() => {
        flashNotificationsToShow -= 1;
        if (document.title === 'Virtaus') {
          document.title = 'Virtaus - uusi kutsu';
        } else {
          document.title = 'Virtaus';
        }
        if (flashNotificationsToShow <= 0) {
          userNotificationActive = false;
          clearInterval(flashingAnimationTimer);
        }
      }, 1000);
      pollingTimers.push(flashingAnimationTimer);
    }

    const statusViewIsNotActive = window.location.href.indexOf('mystatus') < 0;
    void invitationReceivedSound.play();
    if (statusViewIsNotActive) {
      setUserNotification('Uusi kutsu saapunut');
    }
  };

  const navigateToMyStatus = () => {
    const currentPage = location.href.split('/').pop();
    if (currentPage) {
      location.href = location.href.replace(currentPage, 'mystatus');
    }
  };

  const handleUserCreatedEvent = (eventId: number) => {
    setUserEventsPollReply(existingEventIds => {
      return [...existingEventIds, eventId];
    });
  };

  const showSoundOnReminder = () => {
    setSoundOnReminder('Muista tarkistaa, että laitteessasi on äänet päällä ääni-ilmoituksia varten.');
  };

  const handleCloseSoundOnReminder = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSoundOnReminder('');
  };

  return (
    <>
      <Navigation />
      <Container maxWidth="xl">
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            {auth.accessToken && <>
              <Route path="/" element={<Navigate to="/mystatus" replace />} />
              <Route path="/room/*" element={<RoomView />} />
              <Route path="/operational/*" element={<OperationalView />} />
              <Route path="/mystatus/*" element={<MyStatusView userEventIds={userEventsPollReply} updateLastCreatedEventId={handleUserCreatedEvent}/>} />
              <Route path="/settings/*" element={<SettingsView />} />
              <Route path="/login/*" element={<LoginView showSoundOnReminder={showSoundOnReminder} />} />
            </>
            }
            {!auth.accessToken &&
            <>
              <Route path="/*" element={<Navigate to="/login" replace />} />
              <Route path="/login/*" element={<LoginView showSoundOnReminder={showSoundOnReminder}/>} />
            </>
            }
          </Routes>
        </Suspense>
      </Container>
      <Snackbar open={networkError.length > 0} autoHideDuration={10000} onClose={() => setNetworkError('')} disableWindowBlurListener>
        <Alert severity={'error'} sx={{ width: '100%' }}>{networkError}</Alert>
      </Snackbar>
      <Snackbar open={userNotification.length > 0} autoHideDuration={20000} onClose={() => setUserNotification('')} disableWindowBlurListener>
        <Alert severity={'info'} sx={{ cursor: 'pointer', width: '100%' }} onClick={navigateToMyStatus}>{userNotification}</Alert>
      </Snackbar>
      <Snackbar open={soundOnReminder.length > 0} autoHideDuration={20000} onClose={handleCloseSoundOnReminder} disableWindowBlurListener anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} sx={{ marginBottom: '120px' }}>
        <Alert severity={'warning'} variant='filled' icon={<Icon>volume_up</Icon>} sx={{ display: 'flex', alignItems: 'center' }}>
          {soundOnReminder}
          <Button color="inherit" size="small" onClick={handleCloseSoundOnReminder}>
              Ok
          </Button>
        </Alert>
      </Snackbar>
      {auth.accessToken &&
        <ChangeRoomDialog
          open={changeRoomDialogOpen}
          closeDialog={() => setChangeRoomDialogOpen(false)}
          label='Täydennä hoitohuoneesi'
          showFlightControlSelection
        />
      }
    </>
  );
};

export default ContainerView;
