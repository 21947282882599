import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { InvitableRoles } from '../models/Role';
import { RoomEndAction } from '../models/Room';
import { NextAvailableStaffMember, StaffMember } from '../models/StaffMember';
import { VirtausEventStaffMember } from '../models/VirtausEvent';


interface NextProfessionalContextType {
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  timeLocked: boolean;
  setTimeLocked: Dispatch<SetStateAction<boolean>>;
  searchingForProfessional: InvitableRoles | null;
  setSearchingForProfessional: Dispatch<SetStateAction<InvitableRoles | null>>;
  professionalFound: boolean;
  setProfessionalFound: Dispatch<SetStateAction<boolean>>;
  invitedProfessional: StaffMember | null;
  setInvitedProfessional: Dispatch<SetStateAction<StaffMember | null>>;
  timedEndAction: RoomEndAction;
  setTimedEndAction: Dispatch<SetStateAction<RoomEndAction>>;
  lastResolvedStaffId: number | null;
  setLastResolvedStaffId: Dispatch<SetStateAction<number | null>>;
  originalStaffMembersInEvent: VirtausEventStaffMember[];
  setOriginalStaffMembersInEvent: Dispatch<SetStateAction<VirtausEventStaffMember[]>>;
  noStaffFoundTime: Date | null;
  setNoStaffFoundTime: Dispatch<SetStateAction<Date | null>>;
  nextProfessionalAvailable: NextAvailableStaffMember | null;
  setNextProfessionalAvailable: Dispatch<SetStateAction<NextAvailableStaffMember | null>>;
  updatePatientEIdentifier: (newPatientEIdentifier: string | undefined) => void;
}

const NextProfessionalContext = createContext<NextProfessionalContextType>({} as NextProfessionalContextType);

interface NextProfessionalProviderProps {
  children: ReactNode;
}

export const defaultEndAction: RoomEndAction = {
  locked: false,
  cleaningTime: 15,
  breakTime: 0,
};

export const NextProfessionalProvider:FC<NextProfessionalProviderProps> = ({ children }) => {
  const [patientEIdentifier, setPatientEIdentifier] = useState<string | undefined>(undefined);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [timeLocked, setTimeLocked] = useState(false);
  const [searchingForProfessional, setSearchingForProfessional] = useState<InvitableRoles | null>(null);
  const [professionalFound, setProfessionalFound] = useState(false);
  const [invitedProfessional, setInvitedProfessional] = useState<StaffMember | null>(null);
  const [timedEndAction, setTimedEndAction] = useState<RoomEndAction>(defaultEndAction);
  const [lastResolvedStaffId, setLastResolvedStaffId] = useState<number | null>(null);
  const [originalStaffMembersInEvent, setOriginalStaffMembersInEvent] = useState<VirtausEventStaffMember[]>([]);
  const [noStaffFoundTime, setNoStaffFoundTime] = useState<Date | null>(null);
  const [nextProfessionalAvailable, setNextProfessionalAvailable] = useState<NextAvailableStaffMember | null>(null);

  function updatePatientEIdentifier(newPatientEIdentifier: string | undefined) {
    if (newPatientEIdentifier !== patientEIdentifier) {
      resetContext();
    }
    setPatientEIdentifier(newPatientEIdentifier);
  }

  function resetContext(): void {
    setActiveTabIndex(0);
    setTimeLocked(false);
    setSearchingForProfessional(null);
    setProfessionalFound(false);
    setInvitedProfessional(null);
    setTimedEndAction(defaultEndAction);
    setLastResolvedStaffId(null);
    setOriginalStaffMembersInEvent([]);
    setNoStaffFoundTime(null);
    setNextProfessionalAvailable(null);
  }

  return (
    <NextProfessionalContext.Provider value={{
      activeTabIndex,
      setActiveTabIndex,
      timeLocked,
      setTimeLocked,
      searchingForProfessional,
      setSearchingForProfessional,
      professionalFound,
      setProfessionalFound,
      invitedProfessional,
      setInvitedProfessional,
      timedEndAction,
      setTimedEndAction,
      lastResolvedStaffId,
      setLastResolvedStaffId,
      originalStaffMembersInEvent,
      setOriginalStaffMembersInEvent,
      noStaffFoundTime,
      setNoStaffFoundTime,
      nextProfessionalAvailable,
      setNextProfessionalAvailable,
      updatePatientEIdentifier,
    }}>
      {children}
    </NextProfessionalContext.Provider>
  );
};

export default NextProfessionalContext;