import { createContext, FC, ReactNode, useState } from 'react';
import { Auth } from '../models/Auth';

interface AuthContextType {
  auth: Auth;
  setAuth: (auth: Auth) => void;
  removeAuth: () => void;
}

const AuthContext = createContext<AuthContextType>({
  auth: {},
  setAuth: () => {},
  removeAuth() {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider:FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuthState] = useState<Auth>(sessionStorage.getItem('auth') ? JSON.parse(sessionStorage.getItem('auth') as string) as Auth : {});

  function setAuth(newAuth: Auth) {
    removeAuth();
    sessionStorage.setItem('auth', JSON.stringify(newAuth));
    setAuthState(newAuth);
  }

  function removeAuth() {
    setAuthState({});
    sessionStorage.removeItem('auth');
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, removeAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;