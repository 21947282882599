import axios from 'axios';
import { Auth } from '../models/Auth';

export const initializeInterceptors = (auth: Auth, removeAuth: () => void) => {

  axios.interceptors.request.clear();
  axios.interceptors.request.use(
    async config => {
      const accessToken = auth.accessToken;


      if (accessToken && config.headers) {
        config.headers.Authorization = 'Bearer ' + accessToken;
      }

      // Uncomment next row for local development
	    // config.baseURL='http://localhost:8080';
      return config;
    },
    error => {
      if (error.response && error.response.status === 401 ) { // may need lodash get insted of error.response.status
        removeAuth();
      }

      return Promise.reject(error);
    },
  );

  axios.interceptors.response.clear();
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401 ) { // may need lodash get insted of error.response.status
        removeAuth();
      }

      return Promise.reject(error);
    },
  );
};
