import { isEqual } from 'lodash';
import dateTimeService from './DateTimeService';
import api from '../api/Api';
import { LogEvent } from '../models/LogEvent';

class EventLogService {

  private events: LogEvent[] = [];
  constructor() {
    this.handleUnsentEvents();
  }

  isDuplicateEvent(eventA: LogEvent, eventB: LogEvent) {
    const aWithoutTimestamp = { ...eventA, timestamp: undefined };
    const bWithoutTimestamp = { ...eventB, timestamp: undefined };
    if (isEqual(aWithoutTimestamp, bWithoutTimestamp) && eventA.timestamp && eventB.timestamp) {
      const aTimestampRounded = eventA.timestamp.split('.')[0]+'Z';
      const bTimestampRounded = eventB.timestamp.split('.')[0]+'Z';
      if (aTimestampRounded === bTimestampRounded) {
        return true;
      }
    }
    return false;
  }

  sendEventToLog(logEvent: LogEvent) {
    api.logEvent(logEvent).then(() => {
      console.log('LETS LOG EVENT: ' + JSON.stringify(logEvent));
    },
    ).catch((error) => {
      console.log('Event log error: ' + error);
    });
  }

  handleUnsentEvents() {
    const storedUnsentEvents = localStorage.getItem('unsentEvents');
    if (storedUnsentEvents) {
      let eventsToSend: LogEvent[] = JSON.parse(storedUnsentEvents);
      try {
        while (eventsToSend.length > 0) {
          const event = eventsToSend.splice(0, 1)[0];
          this.sendEventToLog(event);
        }
      } catch (error) {

      }
    }
  }

  logEvent(event: LogEvent, debugMode: boolean = false) {
    const timestampedEvent = !debugMode ? { ...event, timestamp: dateTimeService.getTimestamp() } : { ...event };

    if (this.events.some((value) => {
      this.isDuplicateEvent(value, timestampedEvent);
    })) {
      console.log('Event log error: Duplicate event detected. Logging canceled. Event: ' + JSON.stringify(timestampedEvent));
    } else {
      setTimeout(() => {
        try {
          this.sendEventToLog(event);
        } catch (error) {
          const storedUnsentEvents = localStorage.getItem('unsentEvents');
          let unsentEvents:LogEvent[] = storedUnsentEvents ? JSON.parse(storedUnsentEvents) : [];
          unsentEvents.push(event);
          localStorage.setItem('unsentEvents', JSON.stringify(unsentEvents));
        }
        this.handleUnsentEvents();
      }, 0);
      this.events.push(timestampedEvent);
    }
  };

  async logLogout(event: LogEvent) {
    const timestampedEvent = { ...event, timestamp: dateTimeService.getTimestamp() };

    if (this.events.some((value) => {
      this.isDuplicateEvent(value, timestampedEvent);
    })) {
      console.log('Event log error: Duplicate event detected. Logging canceled. Event: ' + JSON.stringify(timestampedEvent));
    } else {
      try {
        this.sendEventToLog(event);
      } catch (error) {
        const storedUnsentEvents = localStorage.getItem('unsentEvents');
        let unsentEvents:LogEvent[] = storedUnsentEvents ? JSON.parse(storedUnsentEvents) : [];
        unsentEvents.push(event);
        localStorage.setItem('unsentEvents', JSON.stringify(unsentEvents));
      }
      this.handleUnsentEvents();
      this.events.push(timestampedEvent);
    }
  };

}

const eventLogService = new EventLogService();
export default eventLogService;


