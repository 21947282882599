import { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react';
import api from '../api/Api';
import { NextHappening } from '../models/NextHappening';
import { VirtausEvent } from '../models/VirtausEvent';
import eventService from '../services/EventService';


interface EventLockContextType {
  eventLocked: boolean | undefined;
  eventUpdated: boolean;
  setEventLocked: (locked: boolean) => void;
  setEvent: (event: VirtausEvent | undefined) => void;

}

const EventLockContext = createContext<EventLockContextType>({
  eventLocked: undefined,
  eventUpdated: false,
  setEventLocked: () => { },
  setEvent: () => { },
});

interface EventLockProviderProps {
  children: ReactNode;
}

export const EventLockProvider: FC<EventLockProviderProps> = ({ children }) => {
  const [eventLocked, setEventLocked] = useState<boolean | undefined>(true);
  const [storedEvent, setStoredEvent] = useState<VirtausEvent | undefined>(undefined);
  const [eventUpdated, setEventUpdated] = useState<boolean>(false);
  const eventRef = useRef<VirtausEvent | undefined>();
  eventRef.current = storedEvent;


  function setEvent(event?: VirtausEvent) {
    if (!event) return;
    if (storedEvent && event.patient.eidentifier !== storedEvent.patient.eidentifier) {
      setEventLocked(false);
    }
    setStoredEvent(event);
    setEventUpdated(false);
  }

  const upkeepTimeLock = () => {
    if (!eventRef.current) return;

    if (eventService.isEventTimeLocked(eventRef.current)) {
      setEventLocked(true);
      if (!eventRef.current.nextHappening) {
        void api.editEvent({ id: eventRef.current.id, nextHappening: NextHappening.ROOM_CLEANUP, cleaningTime: eventRef.current.cleaningTime })
          .then(() => setEventUpdated(true));
      }
    } else {
      setEventLocked(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      upkeepTimeLock();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <EventLockContext.Provider value={{ eventLocked, setEventLocked, setEvent, eventUpdated }}>
      {children}
    </EventLockContext.Provider>
  );
};

export default EventLockContext;